import 'firebase/firestore'

export default (db) => {
   if (location.hostname === "localhost") {
      db.useEmulator("localhost", 3001);
      
   }

   return {}
}
