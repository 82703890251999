import firebaseConfig, { FireBaseAuthErrors } from './config'
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/functions";

import router from './../../router'
import appAuth from './auth'
import appStorage from './storage'
import appDatabase from './database'

 
 
export default { 
    install (Vue) {
        // Initialize Firebase
        firebase.initializeApp(firebaseConfig);
        firebase.auth().useDeviceLanguage();
        firebase.analytics()
        const fns = firebase.functions()
        if (location.hostname === "localhost") {
            fns.useEmulator("localhost", 5001);
        }
        Vue.prototype.$fns = fns

        const storage = firebase.storage();
        const db = firebase.firestore()
        const state = Vue.observable({ 
            error: '',
            user: null,
            claims: null,
            appLoading: true,
            profileValid: null,
            prevRoute: null
        })

        const { methods: storageMethods } = appStorage(storage)
        const { methods: databaseMethods } = appDatabase(db)
        
      
        const updateUser = async (user) => {
            state.claims = null
            state.user = null // <-- update this.currentUser when property change
            state.user = user
           
            if (user) {
                let { claims } = await user.getIdTokenResult(true)
                state.claims = claims
            }

          
        }
        const { computed: authComputed, methods: authMethods } = appAuth(state, firebase, updateUser)

      

        const clean = txt => txt.trim().toLowerCase()
        
        const reRouteByAuthStatus = (to, user) => {
            const { meta, query } = to

            if (meta) {
                let { loginModes, requireAuth, requireAuthModes, role } = meta

                //auth route
                if (to.name == 'Auth' && query) {
                    let { mode } = query
                    mode = typeof mode == 'string' ? clean(mode) : null

                    // // example /auth?mode=completeProfile
                    if (requireAuthModes && requireAuthModes.map(clean).includes(mode) && !user)
                        return '/auth?mode=login'

                    if (mode == 'completeprofile' && user && user.displayName)
                        return '/dashboard'
                        
                    // login mode and auth
                    if (loginModes && loginModes.map(clean).includes(mode) && user )
                        if (user.displayName)
                            return '/dashboard'
                        else 
                            return'/auth?mode=completeProfile'



                    

                } else if (requireAuth && !user)
                    return '/auth?mode=login'
                else if (requireAuth && user && !user.displayName)
                    return '/auth?mode=completeProfile'
                else if (requireAuth && user && role) {
                    const roles =  role.split('|').map(r => r.trim().toLowerCase())
                    if (!roles.includes(state.claims.role))
                        return '/404'
                }

            }
        }
        // to stop the treggering of the  fn: "reRouteByAuthStatus" on app first lunch to wait for user status to change 
        let appLunched = false

        router.beforeEach((to, from, next) => {
            state.prevRoute = from

            if (!appLunched) return next()
            const user = firebase.auth().currentUser
            next(reRouteByAuthStatus(to, user))

        })
       

        firebase.auth().onAuthStateChanged(async user => {
            if (!appLunched) 
                appLunched = true;

            await updateUser(user) 
            

            const { currentRoute: to } = router
            
            const reRout = reRouteByAuthStatus(to, user)

            if (reRout)
                return router.replace(reRout).then(() => state.appLoading = false)
             

            state.appLoading = false

        })


        Vue.mixin({
            computed: {
                $prevRoute () {
                    return state.prevRoute
                },
                appLoading () {
                    return state.appLoading
                },
                authError () {
                    return state.error
                },
                profileValid() {
                   return state.profileValid
                },
                database () {
                    return db
                },
                ...authComputed
            },
            methods: {
                getAuthError(error) {
                    return FireBaseAuthErrors[error.code]  
                },
                handelError (error) {
                    // handle api error
                    if (error.isAxiosError)
                        error = error.response.data

                    console.log('Error =>', [error]);
                    if (error.code)
                        state.error = this.getAuthError(error) || error.message
                    else 
                        state.error = error.message
                    throw error
                },
                resetAuthError  () {
                    state.error = null
                },
                ...authMethods,
                ...storageMethods,
                ...databaseMethods
            }
        })
        
    }
}