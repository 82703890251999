export default {
    apiKey: "AIzaSyCFqKmZI6QGOmU4IFG4g_4yvdtTr9xVOjk",
    authDomain: "monasbat-ee2c4.firebaseapp.com",
    projectId: "monasbat-ee2c4",
    storageBucket: "monasbat-ee2c4.appspot.com",
    messagingSenderId: "479426114690",
    appId: "1:479426114690:web:cf2b657b56dbd74d7cf09b",
    measurementId: "G-0ZLYMG1F1M"
};




export const FireBaseAuthErrors = {
    "auth/too-many-requests": 'لقد حظرنا جميع الطلبات الواردة من هذا الجهاز نظرًا لوجود نشاط غير عادي. أعد المحاولة لاحقًا.',
    'auth/requires-recent-login': 'هذه العملية حساسة وتتطلب مصادقة حديثة. قم بتسجيل الدخول مرة أخرى قبل إعادة محاولة هذا الطلب.',
    'auth/claims-too-large': 'تتجاوز حمولة المطالبات المقدمة إلى setCustomUserClaims() الحد الأقصى للحجم المسموح به وهو 1000 بايت.',
    'auth/email-already-exists': 'البريد الإلكتروني المقدم قيد الاستخدام بالفعل من قبل مستخدم حالي. يجب أن يكون لكل مستخدم بريد إلكتروني فريد.',
    'auth/id-token-expired': 'انتهت صلاحية رمز معرف Firebase المميز.',
    'auth/id-token-revoked': 'تم إبطال رمز معرف Firebase المميز.',
    'auth/insufficient-permission': 'بيانات الاعتماد المستخدمة لتهيئة Admin SDK ليس لديها إذن كافٍ للوصول إلى مورد المصادقة المطلوب. راجع إعداد مشروع Firebase للحصول على وثائق حول كيفية إنشاء بيانات اعتماد بأذونات مناسبة واستخدامها لمصادقة حزم SDK للمشرف.',
    'auth/internal-error': 'واجه خادم المصادقة خطأ غير متوقع أثناء محاولة معالجة الطلب. يجب أن تحتوي رسالة الخطأ على الاستجابة من خادم المصادقة التي تحتوي على معلومات إضافية. إذا استمر الخطأ ، فالرجاء الإبلاغ عن المشكلة إلى قناة دعم تقرير الأخطاء .',
    'auth/invalid-argument': 'تم توفير وسيطة غير صالحة لطريقة المصادقة. يجب أن تحتوي رسالة الخطأ على معلومات إضافية.',
    'auth/invalid-claims': 'سمات المطالبة المخصصة المقدمة إلى setCustomUserClaims() غير صالحة.',
    'auth/invalid-continue-uri': 'يجب أن يكون عنوان URL للمتابعة سلسلة URL صالحة.',
    'auth/invalid-creation-time': 'يجب أن يكون وقت الإنشاء سلسلة تاريخ UTC صالحة.',
    'auth/invalid-credential': 'لا يمكن استخدام بيانات الاعتماد المستخدمة لمصادقة حزم SDK للمشرف لتنفيذ الإجراء المطلوب. تتطلب بعض أساليب المصادقة مثل createCustomToken() و verifyIdToken() تهيئة SDK باستخدام بيانات اعتماد الشهادة بدلاً من رمز التحديث المميز أو بيانات اعتماد التطبيق الافتراضية. راجع تهيئة SDK للحصول على وثائق حول كيفية مصادقة حزم SDK للمشرف باستخدام بيانات اعتماد الشهادة.',
    'auth/invalid-disabled-field': 'القيمة المقدمة لخاصية المستخدم disabled غير صالحة. يجب أن يكون منطقيًا.',
    'auth/invalid-display-name': 'القيمة المقدمة لخاصية المستخدم displayName غير صالحة. يجب أن تكون سلسلة غير فارغة.',
    'auth/invalid-dynamic-link-domain': 'لم يتم تكوين نطاق الارتباط الديناميكي المقدم أو ترخيصه للمشروع الحالي.',
    'auth/invalid-email': 'القيمة المقدمة لخاصية مستخدم email غير صالحة. يجب أن يكون عنوان بريد إلكتروني سلسلة.',
    'auth/invalid-email-verified': 'القيمة المقدمة emailVerified المستخدم emailVerified غير صالحة. يجب أن يكون منطقيًا.',
    'auth/invalid-hash-algorithm': 'يجب أن تتطابق خوارزمية التجزئة مع إحدى السلاسل الموجودة في قائمة الخوارزميات المدعومة.',
    'auth/invalid-hash-block-size': 'يجب أن يكون حجم كتلة التجزئة رقمًا صالحًا.',
    'auth/invalid-hash-derived-key-length': 'يجب أن يكون طول المفتاح المشتق من التجزئة رقمًا صالحًا.',
    'auth/invalid-hash-key': 'يجب أن يكون مفتاح التجزئة مخزنًا مؤقتًا صالحًا للبايت.',
    'auth/invalid-hash-memory-cost': 'يجب أن تكون تكلفة ذاكرة التجزئة رقمًا صالحًا.',
    'auth/invalid-hash-parallelization': 'يجب أن يكون موازاة التجزئة رقمًا صالحًا.',
    'auth/invalid-hash-rounds': 'يجب أن تكون جولات التجزئة رقمًا صالحًا.',
    'auth/invalid-hash-salt-separator': 'يجب أن يكون حقل فاصل ملح خوارزمية التجزئة مخزنًا مؤقتًا صالحًا للبايت.',
    'auth/invalid-id-token': 'رمز المعرف المقدم ليس رمزًا مميزًا صالحًا لمعرف Firebase.',
    'auth/invalid-last-sign-in-time': 'يجب أن يكون وقت تسجيل الدخول الأخير سلسلة تاريخ UTC صالحة.',
    'auth/invalid-page-token': 'رمز الصفحة التالية المقدم في listUsers() غير صالح. يجب أن تكون سلسلة غير فارغة صالحة.',
    'auth/invalid-password': 'القيمة المقدمة لخاصية مستخدم password غير صالحة. يجب أن تكون سلسلة مكونة من ستة أحرف على الأقل.',
    'auth/invalid-password-hash': 'يجب أن تكون تجزئة كلمة المرور مخزنًا مؤقتًا صالحًا للبايت.',
    'auth/invalid-password-salt': 'يجب أن يكون ملح كلمة المرور عبارة عن مخزن مؤقت للبايت صالح',
    'auth/invalid-phone-number': 'القيمة المقدمة phoneNumber غير صالحة. يجب أن تكون سلسلة معرّف متوافقة مع معيار E.164 غير فارغة.',
    'auth/invalid-photo-url': 'القيمة المقدمة photoURL المستخدم photoURL غير صالحة. يجب أن يكون عنوان URL لسلسلة.',
    'auth/invalid-provider-data': 'يجب أن تكون ProviderData مجموعة صالحة من كائنات UserInfo.',
    'auth/invalid-provider-id': 'يجب أن يكون معرف الموفر سلسلة معرف موفر معتمد صالح.',
    'auth/invalid-oauth-responsetype': 'يجب تعيين نوع responseType OAuth واحد فقط على "صحيح".',
    'auth/invalid-session-cookie-duration': 'يجب أن تكون مدة ملف تعريف الارتباط للجلسة رقمًا صالحًا بالمللي ثانية بين 5 دقائق وأسبوعين.',
    'auth/invalid-uid': 'يجب أن يكون uid المقدم سلسلة غير فارغة بحد أقصى 128 حرفًا.',
    'auth/invalid-user-import': 'سجل المستخدم المراد استيراده غير صالح.',
    'auth/maximum-user-count-exceeded': 'تم تجاوز الحد الأقصى المسموح به لعدد المستخدمين للاستيراد.',
    'auth/missing-android-pkg-name': 'يجب تقديم اسم حزمة Android إذا كان تطبيق Android مطلوبًا للتثبيت.',
    'auth/missing-continue-uri': 'يجب تقديم عنوان URL صالح للمتابعة في الطلب.',
    'auth/missing-hash-algorithm': 'يتطلب استيراد المستخدمين باستخدام تجزئات كلمة المرور توفير خوارزمية التجزئة ومعلماتها.',
    'auth/missing-ios-bundle-id': 'يفتقد الطلب معرف حزمة iOS.',
    'auth/missing-uid': 'مطلوب معرف uid للعملية الحالية.',
    'auth/missing-oauth-client-secret': 'سر عميل تهيئة OAuth مطلوب لتمكين تدفق رمز OIDC.',
    'auth/operation-not-allowed': 'تم تعطيل موفر تسجيل الدخول المقدم لمشروع Firebase الخاص بك. قم بتمكينه من قسم طريقة تسجيل الدخول في وحدة تحكم Firebase.',
    'auth/phone-number-already-exists': 'phoneNumber المقدم قيد الاستخدام بالفعل من قبل مستخدم حالي. يجب أن يكون لكل مستخدم phoneNumber فريد.',
    'auth/project-not-found': 'لم يتم العثور على مشروع Firebase لبيانات الاعتماد المستخدمة لتهيئة حزم SDK للمشرف. راجع إعداد مشروع Firebase للحصول على وثائق حول كيفية إنشاء بيانات اعتماد لمشروعك واستخدامها لمصادقة حزم SDK للمشرف.',
    'auth/reserved-claims': 'واحدة أو أكثر من مطالبات المستخدم المخصصة المقدمة إلى setCustomUserClaims() محجوزة. على سبيل المثال ، يجب عدم استخدام مطالبات محددة لـ OIDC مثل (sub ، iat ، iss ، exp ، aud ، auth_time ، إلخ) كمفاتيح للمطالبات المخصصة.',
    'auth/session-cookie-expired': 'انتهت صلاحية ملف تعريف ارتباط جلسة Firebase المقدم.',
    'auth/session-cookie-revoked': 'تم إبطال ملف تعريف ارتباط جلسة Firebase.',
    'auth/uid-already-exists': 'uid المقدم قيد الاستخدام بالفعل من قبل مستخدم حالي. يجب أن يكون لكل مستخدم uid فريد فريد.',
    'auth/unauthorized-continue-uri': 'نطاق عنوان URL للمتابعة غير مدرج في القائمة البيضاء. أضف النطاق إلى القائمة البيضاء في Firebase Console.',
    'auth/user-not-found': 'المستخدم غير موجود او تم حذفه.',
    'auth/invalid-action-code': 'رمز التأكيد غير صالح. يمكن أن يحدث هذا إذا كان الرمز غير صحيح أو منتهي الصلاحية أو تم استخدامه بالفعل.',
    'auth/credential-already-in-use': 'بيانات الاعتماد هذه مرتبطة بالفعل بحساب مستخدم مختلف.',
    "auth/email-already-in-use": 'حدث خطأ! عنوان البريد الإلكتروني قيد الاستخدام من قبل حساب آخر.',
    "auth/wrong-password": 'حدث خطأ! كلمة المرور غير صالحة أو ليس لدى المستخدم كلمة مرور.',
}