import Vuetify from "./vuetify"
import api from './api'
import moment from "moment"
export default {
    install(Vue) {
        moment.locale(Vuetify.framework.lang.current)
        Vue.prototype.$moment = moment
        const state = Vue.observable({
            notifications: []
        })

        Vue.prototype.$bus = new Vue()
        Vue.prototype.$api = api()

        const _t = function () {
            return Vuetify.framework.lang.translator(...arguments)
        }
 
        const _date = function (value, format = 'YYYY/MM/DD') {
            value = typeof value.toDate == 'function' ? moment(value.toDate()) : moment(value instanceof Date ? value : new Date(value))
           
            switch (format) {
                case 'calendar':
                    return value.calendar()
                case 'fromNow':
                    return value.fromNow()
                    
                default:
                    return value.format(format)
            }

        }

        Vue.prototype.$t = _t
        Vue.filter('t', _t)
        Vue.filter('calendar', value => _date(value, 'calendar'))
        Vue.filter('now', value => _date(value, 'fromNow'))
        Vue.filter('date', _date)
        
        Vue.mixin({
            computed: {
                notifications () {
                    return state.notifications
                },
                breakpoint() {
                    return this.$vuetify.breakpoint
                },
                screenFliped() {
                    const { height, width } = this.breakpoint
                    return height < width
                },
                usersRoles () {
                    return [
                        {
                            text: 'مدير',
                            icon: 'mdi-account-supervisor',
                            value: 'admin'
                        },
                        {
                            text: 'قاعة',
                            icon: 'mdi-stadium-variant',
                            value: 'vendor'
                        },
                        {
                            text: 'مستخدم',
                            icon: 'mdi-account-circle',
                            value: 'account'
                        }
                    ]
                }
            },
            methods: {
                openDialog(type = 'confirm', bind, on, dialogBind = {}, dialogOn = {}) {
                    // get the component name
                    type = type.trim().toLowerCase() 
                    type = type.charAt(0).toUpperCase() + type.substr(1)
                    
                    // set dialog attrs
                    dialogBind = { maxWidth: 450, ...dialogBind}

                    // set the component listeners  
                 
                    const defaultOn = { confirm: () => { }, cancel: () => this.closeDialog()  }
                    on = typeof on == 'function' ? { confirm: on } : on
                    on = Object.assign(defaultOn, on)

                    const is = () => import('@/components/dialogs/' + type)
                    const options = {
                        bind: dialogBind,
                        on: dialogOn,
                        component: {
                            is,
                            bind, 
                            on
                        }
                    }
                    this.$bus.$emit('dialog:open', options)
                    return is
                },
                closeDialog () {
                    this.$bus.$emit('dialog:close')
                },
                dialogLoading(value) {
                    this.$bus.$emit('dialog:loading', value)
                },
                getRole(role) {
                    return this.usersRoles.find(r => r.value == role)
                },
                getFieldRule (rule) {
                    return rule.split('|').map(key => {
                        key = key.trim().toLowerCase()
                        const params = key.substring(key.indexOf('(') + 1, key.indexOf(')')).split(',')
                        key = key.indexOf('(') !== -1 ? key.substring(0, key.indexOf('(')) : key
                        return typeof fieldsRules[key] == 'function' ? fieldsRules[key](...params) : () => true
                    })
                },
                nextNotify() {
                    return state.notifications.pop()
                },
                notify (message, { color, icon }) {
                    
                    state.notifications.push({ message, color, icon })
                },
                passwordStrength(PasswordParameter) {
                    const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
                    const mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')
                    if (strongPassword.test(PasswordParameter)) {
                       return 100
                    } else if (mediumPassword.test(PasswordParameter)) {
                        return 50
                    } else {
                        return 0
                    }
                }
            }
        })


    }
}
const validateMin  = (value, min) => {
    if (typeof value != 'string') return true
    return value.length >= Number(min)
}
const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
const validatePassword = (password) => {
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/g;
    return re.test(String(password).toLowerCase());

}
const fieldsRules = {
    min: (m) => (v) => validateMin(v, m) || `يجب أن لا يقل عدد الحروف عن (${m}) أحرف!`,
    required: () => v => !!v || 'هذا الحقل مطلوب!',
    email: () => v => validateEmail(v) || 'البريد الالكتروني غير صالح',
    password: () => v => validatePassword(v) || 'كلمة المرور غير صالحة, يجب أن لا تقل كلمة المرور عن 8 أحرف و تحتوي على الاقل  على حرف  كبير وحرف صغير ورقم واحد'
}