import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import appPlugin from './plugins/app'
import firebasePlugin from './plugins/firebase'
import router from './router'
import { Plugin } from 'vue-fragment'
Vue.use(Plugin)
Vue.use(appPlugin)
Vue.use(firebasePlugin)
Vue.config.productionTip = false

import './assets/style.css'

import VPassword from '@/components/VPassword'
Vue.component('v-password', VPassword)
import VPhone from '@/components/VPhone'
Vue.component('v-phone', VPhone)
import VProfileImg from '@/components/VProfileImg'
import './registerServiceWorker'
Vue.component('v-profile-img', VProfileImg)



new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
