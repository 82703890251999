<template>
    <div class="v-password">
        
        <v-text-field
            v-bind="$attrs"
            v-on="$listeners"
            :type="type"
            :append-icon="icon"
            @click:append="show = !show"
            autocomplete="current-password"
            @input="input"

        ></v-text-field>
        <v-subheader v-if="showStrength && length">
            قوة كلمة المرور : 
            <v-chip small :color="strengthColor" class="mx-2"> {{strengthText}} </v-chip>
        </v-subheader>
           
        
    </div>
</template>

<script>
export default {
    props: {'show-strength':Boolean, 'lazyValue': String},
    data: () => ({
        show: false,
        strength: 0,
        length: 0,
        strengthColor: 'grey',
        strengthText: ''
    }),
    methods: {
        input (password) {
            this.length = password.length
            this.strength = this.passwordStrength(password)
          
            if (this.strength == 100){
                this.strengthColor = 'success'
                this.strengthText = 'جيدة جداً'
            }else if(this.strength == 50) {
                this.strengthColor = 'warning'
                this.strengthText = 'متوسطة'

            }else if(!this.strength) {
                this.strengthColor = this.length ? 'error' : 'grey'
                this.strengthText = this.length ? 'ضعيفة' : ''

            }

            
        }
    },
    computed: {
        icon () {
            return this.show ? 'mdi-eye' : 'mdi-eye-off'
        },
        type () {
            return this.show ? 'text' : 'password'
        }
    }
}
</script>

<style>

</style>