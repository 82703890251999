import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)




const routes = [
    {
        path: '/auth',
        name: 'Auth',
        component: () => import('./views/Auth.vue'),
        props: route => route.query,
        meta: {
            requireAuthModes: ['completeProfile'],
            loginModes: ['Login', 'Register', 'phoneLogin']
        }
        
    },
    {
        path: '/',
        name: 'Home',
        component: () => import('./views/Home.vue')
    },
    {
        path: '/event/:uid',
        name: 'Event',
        props: true,
        component: () => import('./views/Event.vue')
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('./views/Dashboard.vue'),
        meta: {
            requireAuth: true
        },
        children:[
            {
                path: '/dashboard/',
                name: 'DashboardHome',
                component: () => import('./views/Dashboard/Home.vue'),
                meta: {
                    requireAuth: true
                }
            },
            // {
            //     path: '/dashboard/event',
            //     name: 'DashboardEvent',
            //     component: () => import('./views/Dashboard/Event.vue'),
            //     meta: {
            //         requireAuth: true
            //     }
            // }, 
            {
                path: '/dashboard/seats',
                name: 'DashboardSeats',
                component: () => import('./views/Dashboard/Seats.vue'),
                meta: {
                    requireAuth: true,
                    role: 'admin'

                }
            }, 
            {
                path: '/dashboard/seats/:uid',
                name: 'DashboardSeats',
                component: () => import('./views/Dashboard/SeatsAccount.vue'),
                meta: {
                    requireAuth: true,
                    role: 'account'

                }
            },
            {
                path: '/dashboard/profile',
                name: 'DashboardProfile',
                component: () => import('./views/Dashboard/Profile.vue'),
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/dashboard/users',
                name: 'DashboardUsers',
                component: () => import('./views/Dashboard/Users.vue'),
                meta: {
                    requireAuth: true,
                    role: 'admin' 
                }
            },
            {
                path: '/dashboard/users/:uid',
                name: 'DashboardUsersUser',
                component: () => import('./views/Dashboard/User.vue'),
                meta: {
                    requireAuth: true,
                    role: 'admin' 
                }
            },
            {
                path: '/dashboard/:collection',
                name: 'DashboardCollection',
                component: () => import('./views/Dashboard/Collection.vue'),
                meta: {
                    requireAuth: true,
                }
            },
            {
                path: '/dashboard/:collection/:docId',
                name: 'DashboardCollectionDoc',
                component: () => import('./views/Dashboard/Doc.vue'),
                meta: {
                    requireAuth: true,

                }
            },
        ]
    },
    {
        path: '*',
        name: '404',
        component: () => import('./views/404.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

 
export default router
