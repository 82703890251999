import axios from 'axios'
import firebase from 'firebase/app' 



let baseUrl = 'https://us-central1-monasbat-ee2c4.cloudfunctions.net/api'

// if (location.hostname === "localhost") {
//     baseUrl = 'http://localhost:5001/monasbat-ee2c4/us-central1/api'
// }


// const instance = axios.instance
// console.log(instance);
// getIdToken
axios.interceptors.request.use(async function (config) {
    config.url = baseUrl + config.url
    const token = await firebase.auth().currentUser.getIdToken(true) 
    config.headers['authorization'] = token
    // config.headers['Access-Control-Allow-Origin'] = '*'
    return config;
}, function (error) {
    return Promise.reject(error);
});

export default () => ({
    get(url) {
        return axios.get(url)
    },
    post(url, data) {
        return axios.post(url, data)
    },
    put(url, data) {
        return axios.put(url, data)
    },
    delete(url) {
        return axios.delete(url)
    }
})