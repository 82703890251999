<template>
<v-app app>
 

  <v-main v-if="appLoading" class="primary">
    <v-overlay absolute color="transparent">
      <v-card flat color="transparent" class="text-center">
        <v-img src="@/assets/logo-white.png"></v-img>
        <v-progress-circular
          color="white"
          indeterminate
          class="mx-auto my-6"
        >

        </v-progress-circular>
        <div class="title">
          الرجاء الأنتظار...
        </div>
      </v-card>
    </v-overlay>
    
  </v-main>
  <router-view v-else ></router-view>
 


   <v-snackbar v-model="snackbar.active" :color="snackbar.color" >
     <v-icon v-if="snackbar.icon"> {{ snackbar.icon }} </v-icon>
     <span v-html="snackbar.message"></span>
   </v-snackbar>

   <v-dialog v-model="dialog.value"
      v-bind="dialog.bind"
      v-on="dialog.on"  
      :persistent="dialog.loading || (dialog.bind ? dialog.bind.persistent : false)"
      >
      <component 
        ref="dialog"
        v-if="dialog.component && dialog.component.is" 
        :is="dialog.component.is"
        v-bind="dialog.component.bind"
        v-on="dialog.component.on"
        :loading="dialog.loading"
      ></component>
   </v-dialog>
</v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    dialog: {
      value: false
    },
    snackbar: {
      active: false,
      message: null,
      color: null,
    }
  }),
  watch: {
    notifications (notifications) {
      if (!this.snackbar.active && notifications.length)
        this.snackbar.active = true
    },
    'snackbar.active' (active ) {
      if(!active){
        if (this.notifications.length > 0)
        setTimeout(() => {
          this.snackbar.active = true
        }, 200)
        else {
          this.snackbar.icon = null
          this.snackbar.message = null
          this.snackbar.color = null
        }
      } else {
        const { message, color, icon } = this.nextNotify()
        this.snackbar.icon = icon
        this.snackbar.message = message
        this.snackbar.color = color
      }
    },
    authError (message) {
      if (message) {
        this.notify(message, {color: 'error', icon: 'mdi-alert'})
        this.resetAuthError()
      }
    }
  },
  mounted () {
    window.addEventListener('beforeinstallprompt', (e) => {
      console.log(e);
      // // Prevent Chrome 67 and earlier from automatically showing the prompt
      // e.preventDefault();
      // // Stash the event so it can be triggered later.
      // const deferredPrompt = e;
      // // Update UI to notify the user they can add to home screen
      // addBtn.style.display = 'block';

      // addBtn.addEventListener('click', (e) => {
      //   // hide our user interface that shows our A2HS button
      //   addBtn.style.display = 'none';
      //   // Show the prompt
      //   deferredPrompt.prompt();
      //   // Wait for the user to respond to the prompt
      //   deferredPrompt.userChoice.then((choiceResult) => {
      //       if (choiceResult.outcome === 'accepted') {
      //         console.log('User accepted the A2HS prompt');
      //       } else {
      //         console.log('User dismissed the A2HS prompt');
      //       }
      //       deferredPrompt = null;
      //     });
      // });
    
    });
  },
  created () {
    this.$bus.$on('dialog:open', options => {
      this.dialog = { value: true, loading: false, ...options }
    
    })

    this.$bus.$on('dialog:close', () => {
      this.dialog = { value: false, loading: false }
      
    })

    this.$bus.$on('dialog:loading', value => {
      this.dialog.loading = value
    })
  },
  beforeDestroy () {
    this.$bus.$off('dialog:open')
    this.$bus.$off('dialog:close')
    this.$bus.$off('dialog:loading')
  }

};
</script>
