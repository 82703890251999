import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
import ar from 'vuetify/lib/locale/ar'
import appAr from '@/locales/ar'


export default new Vuetify({
    rtl: true,
    lang: {
        locales: { ar: { ...ar, ...appAr } },
        current: 'ar',
    },
    theme: {
        themes: {
            light: {
                primary: '#38B6FF',
                // secondary: '#ffeb3b',
                // accent: '#8c9eff',
                // error: '#b71c1c',
            },
        },
    },
});
