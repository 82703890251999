export default {    
    "_":"",
    "welcome_message": "مرحباً <b>{0}</b>, لقد تم دعوتك لحضور",
    "date_desc": " وذلك في تاريخ <b>{0}</b> عند تمام الساعة <b>{1}</b>",
    "accept_invintation": "تأكيد حضوري",
    "not_sure": "لست متأكداً",
    "denie_invintation": "لن أتمكن من الحضور",
    "navagation_details": "تعليمات الوصول",
    "sms_is_sending": "الدعوات قيد الإرسال",
    "confirmed_confiremed_successfuly": "تم تأكيد الحضور بنجاح",
    "confirmed_maybe_successfuly": "تم تغيير الحالة الى لست متأكداً بنجاح",
    "confirmed_denied_successfuly": "تم تأكيد عدم الحضور بنجاح",
    "date": "التاريخ",
    "events": "المناسبات",
    "accept_confirm": "تأكيد حضوري",
    "maybe_confirm": "لست متأكداً من حضوري",
    "denied_confirm": "لن أتمكن من الحضور",
    "enter_guests_number": " الرجاء أدخال عدد الأشخاص القادمين الى المناسبة",
    "maybe_enter_guests_number": "في حالة حضورك, الرجاء أدخال عدد الأشخاص القادمين الى المناسبة",
    "pepole_number": "عدد الأشخاص",
    "confirm_not_comming": "هل أنت متأكد بأنك لن تستطيع الحضور؟",
    "address": "العنوان",
    "name": "الأسم",
    "type": "النوع",
    //global
    "example": "مثال",
    "file_field": "حقل الملف",
    "field": "الحقل",
    "select": "أختر",
    "please_select": "الرجاء الأختيار",
    "select_field_for_value": "قم بتحديد الحقول المراد استيرادها",
    "values": "القييم",
    "required_fields": "الحقول المطلوبة",
    "select_import_file": "اختر ملف الاستيراد. الصيغ المتاحة \"{0}\"",
    "import_file_successfuly": "تم استيراد \"<strong>{0}</strong>\" عنصر بنجاح",
    "import_file_empty": "الملف الذي أخترته فارغ!",
    "seconds": "ثواني",
    "minuts": "دقائق",
    "hours": "ساعات",
    "days": "أيام",
    "time_left": "باقي من الزمن لبدأ المناسبة",
    "confirm_comming": "تأكيد حضورك الى المناسبة",
    "ok": "موافق",
    "firstname": "الأسم الشخصي",
    "lastname": "أسم العائلة",
    "dashboard": "لوحة التحكم",
    "users": "المستخدمين",
    "user": "المستخدم",
    "confirm": "تأكيد",
    "cancel": "إلغاء",
    "settings": "خيارات",
    "edit": "تعديل",
    "delete": "حذف",
    "disabled": "معطل",
    "disable": "تعطيل",
    "enable": "تفعيل",
    "send": "إرسال",
    "save": "حفظ",
    "save_and_new":"حفظ وجديد",
    "status": "الحالة",
    "phone": "الهاتف",
    "fullname": "الأسم الكامل",
    "table": "الطاولة",
    "select_a_file": "أختيار الملف",
    "import": "إستيراد",
    "update": "تحديث",
    "update_successfuly": "تم التحديث بنجاح",
    "password": "'كلمة السر'",
    "email": "البريد الالكتروني",
    "role": "نوع المستخدم",


    "confirm_deletion": "تأكيد الحذف",
    "confirm_deletion_text": "هل أنت متأكد بأنك تريد حذف  \"<strong>{0}</strong>\"؟",
    "deleted_successfuly": "تم حذف  \"<strong>{0}</strong>\" بنجاح",
    "created_successfuly": "تمة أضافة \"<strong>{0}</strong>\" بنجاح",



    //events 
    "event_details": "تفاصيل المناسبة",
    "event_guests": "قائمة الضيوف",
    "event_seats": "تنظيم الجلوس",

    // types 
    "wedding": "حفل زفاف",
    "birthday": "عيد ميلاد",
    "personal": "مناسبة شخصية",
    "business": "مناسبة عمل",
    // form
    "my_events": "مُنَاسَبَاتِي",
    "my_event": "مُنَاسَبَتِي",
    "add_event": "أضافة مناسبة جديدة",
    "event_type": "نوع المناسبة",
    "event_date": "في تاريخ",
    "event_me": "أسمي",
    "event_saved_successfuly":"تم حفظ تفاصيل المناسبة بنجاح",
    "event_partner": "نصفي الثاني",
    "event_person": "صاحب المناسبة",
    "event_time": "عند الساعة",
    "event_location": "مكان المناسبة",
    "event_address": "عنوان المناسبة",
    "edit_your_event": "عدل تفاصيل مناسبتك",
    "set_your_event": "ادخل  تفاصيل مناسبتك ",
    //guests
    "guests_desc": "قائمة المدعوين الى المناسبة",
    "delete_guest": "حذف الضيف",
    "confirm_guest_deletion": "تأكيد حذف الضيف",
    "confirm_guest_deletion_text": "هل أنت متأكد بأنك تريد حذف الضيف \"<strong>{0}</strong>\"؟",
    "add_guest": "إضافة ضيف جديد",
    "import_guests": "إستيراد الضيوف",
    "guests_number": "عدد الضيوف",
    "waiting_confirmation": "في أنتظار التأكيد",
    "confiremeds": "قادمين",
    "denieds": "لن يأتوا",
    "maybes": "ليسوا متأكدين",
    "confiremed": "قادم",
    "denied": "لن أتي",
    "maybe": "ربما",
    "guest_deleted_successfuly": "تم حذف الضيف \"<strong>{0}</strong>\" بنجاح",
    "guest_created_successfuly": "تمة أضافة الضيف \"<strong>{0}</strong>\" بنجاح",
    "guest_updated_successfuly": "تم تعديل الضيف \"<strong>{0}</strong>\" بنجاح",
    "sms_status": "حالة الرسالة (SMS)",
    "guest_name": "اسم الضيف",
    // users
    "delete_account": "حذف المستخدم",
    "select_user": "أختر المستخدم",
    "confirm_user_deletion": "تأكيد حذف المستخدم",
    "confirm_user_deletion_text": "هل أنت متأكد بأنك تريد حذف المستخدم \"<strong>{0}</strong>\"؟",
    "confirm_user_disable": "تأكيد تعطيل المستخدم",
    "confirm_user_disable_text": "هل أنت متأكد بأنك تريد تعطيل المستخدم \"<strong>{0}</strong>\"؟" ,
    "confirm_user_enable": "تأكيد تفعيل المستخدم",
    "confirm_user_enable_text": "هل أنت متأكد بأنك تريد تفعيل المستخدم \"<strong>{0}</strong>\"؟",
    "users_headers": {
        "displayName": "المستخدم",
        "role": "نوع المستخدم",
        "email": "البريد الالكتروني",
        "phoneNumber": "الهاتف",
        "lastSignInTime": "تسجيل الدخول",
        "creationTime": "تاريخ الأنشاء",
    },
    "add_user": "أضافة مستخدم جديد",
    "profile":"الملف الشخصي",
    "table_not_selected": "لم يتم أختيار الطاولة!",

    

    // invintations
    "invitations_cards": "بطاقات الدعوة",
    "invitations_list": "قائمة الدعوات المرسلة",
    "invitations_desc": "إرسال الدعوات (SMS) الى الضيوف",
    "send_invitations": "إرسال الدعوات",
    "send_messages_to": " إرسال بطاقات الدعوة  الى \"<strong>{0}</strong>\" من أصل {1} ضيف",
    "all_guests": "جميع الضيوف",
    "seated_guests": "جميع الضيوف الذي تم اجلاسهم",
    "select_guests": "أختيار الضيوف",
    "created": "تم الأنشاء",
    "guest_confirm_comming": "تأكيد الحضور",
    // sms status
    "sms": {
        "not_sent": "لم ترسل بعد",
        "accepted": "تمت الموافقة",
        "queued":"في الانتظار",
        "sent": "قيد الإرسال",
        "failed": "فشلت",
        "delivered": "تم اللإرسال",
        "undelivered": "لم يتم اللإرسال",
   
    },


    "user_created_successfuly": "تم أنشاء المستخدم \"<strong>{0}</strong>\" بنجاح",
    "user_updated_successfuly": "تم تعديل المستخدم \"<strong>{0}</strong>\" بنجاح",
    "user_deleted_successfuly": "تم حذف المستخدم \"<strong>{0}</strong>\" بنجاح",
    "user_disabled_successfuly": "تم تعطيل المستخدم \"<strong>{0}</strong>\" بنجاح",
    "user_enabled_successfuly": "تم تفعيل المستخدم \"<strong>{0}</strong>\" بنجاح",

    // form
    "form": {
        "invalid": "حدث خطأ في النموذج! يرجى فحص التفاصيل والمحاولة مجدداٌ"
    },
    //seats
    "add_manage_seats": "إدارة وترتيب المقاعد للضيوف",
    "add_table": "أضافة طاولة / فاصل",
    "edit_table": "تعديل الطاولة / الفاصل \"<strong>{0}</strong>\"",
    "add_the_table": "أضافة",
    "table_name": "أسم الطاولة",
    "table_number": "رقم الطاولة",
    "seats_number":"عدد المقاعد",
    "square_table": "طاولة مربعة",
    "circle_table": "طاولة دائرية",
    "rect_table": "طاولة مستطيلة",
    "spacer": "فاصل",
    "table_type": "نوع الطاولة",
    "number_of_table": "طاولة رقم {0}",
    "number_of_spacer": "فاصل رقم {0}",
    "empty_table": "الطاولة فارغة",
    "empty_seats": "مقاعد شاغرة",
    "seated_seats": "مقاعد محجوزة",
    "tables_count": "عدد الطاولات",
    "event_completed": "أنتهت المناسبة",
    "empty_sketch": "الرسم فارغ! قم بأضافة العناصر الى الرسم",
    "user_not_selected": "لم يتم أختيار المستخدم",
    "table_guests": "ضيوف الطاولة",
    "guest_search": "بحث...",
    "upload_your_sketch": "عذراً, لم تقم بتحميل رسم توزيع الطاولات.",
    "select_an_image": "حمل الرسم. أختر صورة او ملف PDF.",
    "allowed_files": "الملفات المتاحة \"{0}\"",
    "uploading_file_please_wait": "يقوم بتحميل الملف. الرجاء الأنتظار...",
    "uploaded_done": "تم تحميل الملف بنجاح.",
    "sketch_waitin": "في الأنتظار, نقوم بتجهيز توزيع الطاولات.",
    "add_guest_to_table": "أضف ضيف الى الطاولة",
    "tables_sketch": "تحميل رسم توزيع الطاولات",
    "tables_sketch_not_uploaded": "لم يتم رفع رسم توزيع الطاولات من قبل المستخدم",

}