<template>
      <VuePhoneNumberInput 
      no-country-selector8
      class="phone-field"
        v-on="$listeners" v-bind="$attrs" 
        :preferred-countries="['IL']"
        :only-countries="['IL']"
        default-country-code="IL"
        required
        no-country-selector
        no-example
        :translations ="{
                    countrySelectorLabel: 'الرقم الدولي',
                    countrySelectorError: 'اختر دولة',
                    phoneNumberLabel: 'رقم الهاتف',
                    example: 'مثال :'
                 }"
        
        />
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
 
export default {
    components: {
        VuePhoneNumberInput
    },
    data: () => ({
        lookup: {

            countryCode: 'IL'
        }
    }),
    created () {
        // fetch('https://extreme-ip-lookup.com/json').then(res => res.json()).then(ob => this.lookup = ob).catch(this.handelError)
    }
}
</script>

 