
import 'firebase/auth'
export default (state, app, updateUser) => {
    const auth = app.auth()
    if (location.hostname === "localhost") {
        auth.useEmulator("http://localhost:9099");
    }


    return {
        computed: {
            currentUser() {
                return state.user
            },
            currentUserClaims() {
                return state.claims
            },
            isAuth() {
                return !!state.user
            }
        },
        methods: {
            hasRoles(roles) {
                if (!roles) return true
                if (!this.currentUserClaims) return false
                roles = roles.split('|').map(r => r.trim().toLowerCase())
                const { role } = this.currentUserClaims
                // console.log(roles, role);
                return roles.includes(role)
            },
            updateUser(user, additionalUserInfo) {

                updateUser(user, additionalUserInfo)
            },
            reloadCurrentUser() {
                return auth.currentUser.reload().then(() => {
                    this.updateUser(auth.currentUser)
                })
            },
            registerUser({ email, password }) {
                return auth.setPersistence(app.auth.Auth.Persistence.SESSION)
                    .then(() => auth.createUserWithEmailAndPassword(email, password)
                        .then(({ user, additionalUserInfo }) => this.updateUser(user, additionalUserInfo))
                        .catch(this.handelError))
            },
            login({ provider, email, password }) {
                let login = null
                if (provider.trim().toLowerCase() == 'password') {
                    login = auth.signInWithEmailAndPassword(email, password)
                } else {
                    switch (provider.trim().toLowerCase()) {
                        case 'facebook':
                            provider = new app.auth.FacebookAuthProvider()
                            break;
                        case 'google':
                        default:
                            provider = new app.auth.GoogleAuthProvider()
                            break;
                    }

                    login = auth.signInWithPopup(provider)
                }

                return auth.setPersistence(app.auth.Auth.Persistence.SESSION)
                    .then(() => login.then(({ user, additionalUserInfo }) => this.updateUser(user, additionalUserInfo))
                        .catch(this.handelError))

            },
            logout() {
                return auth.signOut().then(() => this.updateUser(null))
                    .catch(this.handelError)
            },
            sendPasswordReset(email) {
                return auth.sendPasswordResetEmail(email)
            },
            sendPasswordResetEmail(email) {
                return auth.sendPasswordResetEmail(email)
            },
            confirmPasswordReset(code, password) {
                return auth.confirmPasswordReset(code, password)
            },
            applyActionCode(code) {
                return auth.applyActionCode(code)
            },
            getRecaptchaVerifier(captchaID) {
                return new app.auth.RecaptchaVerifier(captchaID, { 'size': 'invisible' });
            },
            sendPhoneNumberCode(phoneNumber, verifier) {
                return auth.signInWithPhoneNumber(phoneNumber.trim(), verifier)
                    .then((confirmationResult) => confirmationResult)
            },
            getPhoneCodeCredential(verificationId, code) {
                return app.auth.PhoneAuthProvider.credential(verificationId, code)
            },
            signInwithCredential(credential) {
                return auth.setPersistence(app.auth.Auth.Persistence.SESSION)
                    .then(() => auth.signInWithCredential(credential))
            },
            sendEmailVerification() {
                return this.currentUser.sendEmailVerification()
            },
            updateProfile(profile) {
                return this.currentUser.updateProfile(profile).then(this.reloadCurrentUser)
            },
            updateEmail(email) {
                return this.currentUser.updateEmail(email).then(this.reloadCurrentUser)
            },
            updatePhoneNumber(credential) {
                return this.currentUser.updatePhoneNumber(credential).then(this.reloadCurrentUser)
            }

        }
    }
}





// import 'firebase/auth'
// export default (state, app, updateUser) => ({
//     computed: {
//         currentUser () {
//             return state.user
//         },
//         currentUserClaims() {
//             return state.claims
//         },
//         isAuth() {
//             return !!state.user
//         }
//     },
//     methods: {
//         hasRoles(roles) {
//             if (!roles) return true
//             if (!this.currentUserClaims) return false
//             roles = roles.split('|').map(r => r.trim().toLowerCase())
//             const { role } = this.currentUserClaims
//             console.log(roles, role);
//             return roles.includes(role)
//         },
//         updateUser(user, additionalUserInfo) {
        
//             updateUser(user, additionalUserInfo)
//         },
//         reloadCurrentUser () {
//             return app.auth().currentUser.reload().then(() => {
//                 this.updateUser(app.auth().currentUser)
//             })
//         },
//         registerUser({ email, password }) {
//             return app.auth().setPersistence(app.auth.Auth.Persistence.SESSION)
//                 .then(() => app.auth().createUserWithEmailAndPassword(email, password)
//                     .then(({ user, additionalUserInfo }) => this.updateUser(user, additionalUserInfo))
//                     .catch(this.handelError))
//         },
//         login ({ provider, email, password }) {
//             let login = null
//             if (provider.trim().toLowerCase() == 'password') {
//                 login = app.auth().signInWithEmailAndPassword(email, password)
//             } else {
//                 switch (provider.trim().toLowerCase()) {
//                     case 'facebook':
//                         provider = new app.auth.FacebookAuthProvider()
//                         break;
//                     case 'google':
//                     default:
//                         provider = new app.auth.GoogleAuthProvider()
//                         break;
//                 }

//                 login = app.auth().signInWithPopup(provider)
//             }

//             return app.auth().setPersistence(app.auth.Auth.Persistence.SESSION)
//                 .then(() => login.then(({ user, additionalUserInfo }) => this.updateUser(user, additionalUserInfo))
//                 .catch(this.handelError)) 
                    
//         },
//         logout () {
//             return app.auth().signOut().then(() => this.updateUser(null))
//                 .catch(this.handelError)
//         },
//         sendPasswordReset(email) {
//             return app.auth().sendPasswordResetEmail(email)
//         },
//         sendPasswordResetEmail(email) {
//             return app.auth().sendPasswordResetEmail(email)
//         },
//         confirmPasswordReset(code, password) {
//             return app.auth().confirmPasswordReset(code, password)
//         },
//         applyActionCode(code) {
//             return app.auth().applyActionCode(code)
//         },
//         getRecaptchaVerifier(captchaID) {
//             return new app.auth.RecaptchaVerifier(captchaID, { 'size': 'invisible' });
//         },
//         sendPhoneNumberCode(phoneNumber, verifier) {
//             return app.auth().signInWithPhoneNumber(phoneNumber.trim(), verifier)
//                 .then((confirmationResult) => confirmationResult)
//         },
//         getPhoneCodeCredential(verificationId, code) {
//             return app.auth.PhoneAuthProvider.credential(verificationId, code)
//         },
//         signInwithCredential(credential) {
//             return app.auth().setPersistence(app.auth.Auth.Persistence.SESSION)
//                 .then(() =>  app.auth().signInWithCredential(credential))
//         },
//         sendEmailVerification() {
//             return this.currentUser.sendEmailVerification()
//         },
//         updateProfile (profile) {
//             return this.currentUser.updateProfile(profile).then(this.reloadCurrentUser) 
//         },
//         updateEmail(email) {
//             return this.currentUser.updateEmail(email).then(this.reloadCurrentUser)  
//         },
//         updatePhoneNumber (credential) {
//             return this.currentUser.updatePhoneNumber(credential).then(this.reloadCurrentUser) 
//         }

//     }
// })
