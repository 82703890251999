import 'firebase/storage'
export default (storage) =>{
    if (location.hostname === "localhost") {
        storage.useEmulator("localhost", 9199);
    }
    
    return {
        methods: {
            upload(file, path, metadata = {}) {
                if (!file || !path) throw new Error('undefined "file" or "path" in storage upload')
                return storage.ref().child(path).put(file, metadata)
            }
        }
    }
}
