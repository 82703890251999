<template>
    <div class="profile-img relative my-4" :style="{ height: size + 'px', width: size + 'px' }">
        <v-progress-circular 
        :indeterminate="indeterminate || (loading && !progress)"
        color="primary" :size="Number(size) + 6" :value="progress">
            <v-avatar :size="size" @click="changePhotoURL">
                <img ref="img"  :src="src" />
                <v-skeleton-loader v-if="isLoading"
                :width="size"
                :height="size"
                class="v-skeleton-loader-profile"
                type="avatar"
                ></v-skeleton-loader>
            </v-avatar>
        </v-progress-circular>
       <v-btn  v-if="!isLoading" rounded depressed class="absolute px-0 change-img"  @click="changePhotoURL"  color="grey lighten-4">
           <v-icon>mdi-camera</v-icon>
       </v-btn>

       <v-file-input
        ref="file"
        style="opacity:0"
        class="absolute file-input"
        accept="image/png, image/jpeg"
        v-model="file"
        ></v-file-input>
       <div class="text-center">
           {{label}}
       </div>
    </div>
</template>

<script>
 
export default {

    props:{
        size: {
            type: [Number, String],
            default: 80
        },
        label: String,
        value: String
    },
    data: () => ({
        loading: false,
        file: null,
        src: require('@/assets/profile-img.png'),
        progress: 0,
        indeterminate: false
    }),
    computed:{
        isLoading () {
            return this.indeterminate || this.progress || this.loading
        }
    },
    watch: {
        value: {
            handler (value) {
                this.src = value ? value : require('@/assets/profile-img.png')
            },
            immediate: true
        },
        file (file) {
            if (file) {

                const fileMime = file.name.split('.')[1]
                this.loading = true
                const uploadTask = this.upload(this.file, `users/${this.currentUser.uid}/profile-img.`+fileMime, { contentType: file.type })


                uploadTask.on('state_changed', 
                    (snapshot) => {
                        this.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    }, 
                    this.handlerError, 
                    () => {
                        this.indeterminate = true
                        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                            this.src = downloadURL
                            this.progress = 0
                            this.indeterminate = false
                            this.$emit('change', downloadURL)
                        });
                    }
                );
            }
        }
    },
    methods: {
        changePhotoURL () {
            const { file } = this.$refs
            console.log(file.$el.querySelector('input'));
            file.$el.querySelector('input').click()
        }
    },
    mounted () {
        const { img } = this.$refs
        img.onload = () => this.loading = false
    }
  
    
}
</script>

<style lang="scss" >
.file-input {
    top: 0;
    z-index: -1;
}
.change-img {
    top: 0;
    right: 0;
    min-width: 36px !important;
    min-height: 36px !important;
}
.v-skeleton-loader-profile {
    position: absolute !important;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    .v-skeleton-loader__bone {
        height: 100%;
        width: 100%;
    }
}
</style>

